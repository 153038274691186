import React from "react"

export default function hero() {
  return (
    <section class="relative home-wrapper items-center overflow-hidden">
      <div class="section wf-section">
        <div class="section-container w-container">
          <h1 class="heading">
            We’re on a <span class="span-gradient">mission</span> to help more
            companies succeed
            <br />
          </h1>
        </div>
      </div>

      <div class="section wf-section">
        <div class="section-container w-container">
          <p>
            Launch Turkey is building an all-in-one Company OS to help define
            how founders across the globe launch, manage, and grow their
            businesses.
            <br />
            <br />
            More than ten thousand founders from over a hundred countries have
            used our product, Launch Turkey Start, to launch their startup. For
            many founders, "launchturkey.com" has become what they type into
            their address bars when they think about incorporating a business.{" "}
            <br />
            <br />
            But Launch Turkey is much more than just incorporation. Our team is
            dedicated to solving some of the startups hardest problems, with
            particular focus in:
            <br />
          </p>
          <ul role="list" class="list">
            <li>Democratizing access to financial infrastructure</li>
            <li>
              Automating mundane tasks every founder faces when running a
              business
            </li>
            <li>
              Making tools and resources more accessible to everyone, everywhere
            </li>
            <li>
              Building integrated solutions for founders and removing friction
            </li>
            <li>
              Delivering opportunity to traditionally overlooked founders and
              companies
            </li>
            <li>Democratizing access to knowledge</li>
          </ul>
        </div>
      </div>

      <div class="section wf-section">
        <div class="section-container w-container">
          <h2 class="heading">
            Some of the people who <span class="span-gradient">believe</span> in
            us.
          </h2>
        </div>
      </div>
    </section>
  )
}
