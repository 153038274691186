import * as React from "react"
import Hero from '../components/about/abouthero'
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import Partners from '../components/partners'
import WorkWithUs from '../components/workwithus'




import Seo from "../components/seo"

export default function index() {
  return (
  <>
      <Seo title="Launch Turkey" metaDescription="Launch Turkey is help people to launch their own entity in Turkey easily." />
      <body>
        <Navbar />
        <Hero />
        <Partners />
        <WorkWithUs />
        <Footer />
      </body>
  </>
  )
}
