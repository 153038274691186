import React from "react"

export default function price() {
  return (
    <>
      <div class="section wf-section mb-16">
        <div class="section-container-lg w-container">
          <div class="call-to-action-copy">
            <div class="div-block-377-copy">
              <h3 class="heading-86">Work with us!</h3>
              <p class="paragraph-25">Join our team and work remotely.</p>
              <div class="cta-btn-container">
                <div class="gradient-btn">
                  <div class="gradient--btn mailroom">
                    <a
                      href="https://www.linkedin.com/company/launch-turkey/jobs/"
                      class="gradient---btn w-button"
                    >
                      Go to Careers
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
